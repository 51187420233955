(function($) {

    // functions 

    function toggleFiltersAccordion(){

        $('.filters__heading .toggle').on('touchstart' , function(){
            var heading = $(this).parents('.filters__heading');
            var filters = $('.filters__wrapper');
            if(heading.hasClass('opened')){
                heading.removeClass('opened');
                filters.stop().slideUp('fast');
            }else{
                heading.addClass('opened');
                filters.stop().slideDown('fast');
            }
        });
    }



    $(document).ready(function(){

        // document ready

        // module accordeons
        if($('.accordion__toggle').length > 0){

            $('.accordion__toggle').on('click' , function(e){
                var question = $(this).parents('.accordion__wrapper');
                if(! question.hasClass('active')){
                    question.addClass('active');
                    question.find('.accordion__content').stop().slideDown('fast');
                    if(question.hasClass('contains-radio')){ question.find('.accordion__header input[type="radio"]').prop('checked', true); }
                    /* les autres questions */
                    $('.accordion__wrapper').not(question).each(function(){
                        $(this).removeClass('active');
                        $(this).find('.accordion__content').stop().slideUp('fast');
                        if($(this).hasClass('contains-radio')){ $(this).find('.accordion__header input[type="radio"]').prop('checked', false); }
                    });
                }else{
                    question.removeClass('active');
                    question.find('.accordion__content').stop().slideUp('fast');
                    if(question.hasClass('contains-radio')){ question.find('.accordion__header input[type="radio"]').prop('checked', false); }
                }
                
            });

        }


        // filtres de recherche
        if( $('.filters__heading').length > 0 ){
            toggleFiltersAccordion();
        }

        // candidatures carriere
        if( $('.career__listing__item a.open-form').length > 0 ){
            $('.career__listing__item a.open-form').on('click' , function(){

                var form_accordion = $('section.application .accordion__wrapper');
                var post_title_input = form_accordion.find('form .offer_title input');
                var post_title = $(this).parents('.career__listing__item').data('title');
                var post_interest_select = form_accordion.find('form .interest select');
                var post_interest = $(this).parents('.career__listing__item').data('interest');

                // ouverture accordeon
                if( ! form_accordion.hasClass('active') ){
                    form_accordion.addClass('active');
                    form_accordion.find('.accordion__content').stop().slideDown('fast');
                }

                // remplissage champs formulaire
                if(post_title !== '' && post_title_input.length > 0){
                    post_title_input.val(post_title);
                }
                if(post_interest !== '' && post_interest_select.length > 0){
                    post_interest_select.val(post_interest);
                }
            });
        }

    });
    
    
    })(jQuery);