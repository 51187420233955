(function($) {

    // functions 



    $(document).ready(function(){

        // document ready
        $('.gallery__carousel').flickity({
            wrapAround : true,
        });

        $('.blog__listing__carousel').flickity({
            watchCSS : true,
            wrapAround : true,
            pageDots: false,
            prevNextButtons : false,
        });

    });
    
    
    })(jQuery);