(function ($) {
  
  // This selector also need to be changed in the _dropdown-menu.scss
  var dropdownSelector = ".menu-item-has-children";
  
  /////////////// ON READY /////////////////
  $(function () {
    /////////////// INIT DROPDOWN ///////////////

    // Seulement utiliser du javascript si :focuswithin ne fonctionne pas en CSS
    if ( "undefined" !== Modernizr && ! Modernizr.focuswithin ) {
      var $innerDropdownLink = $( dropdownSelector + ' a' );
      $innerDropdownLink.on('focusin', function () {
        $(this).parents('.dropdown').addClass('is-focused');
      });
      $innerDropdownLink.on('focusout', function () {
        $allParents = $(this).parents( dropdownSelector );

        $allParents.each(function () {
          $parent = $(this);
          if (!$parent.find('a:focus').length) {
            $parent.removeClass('is-focused');
          }
        });
      });
    }

    /**
     * Permet de cliquer sur une partie du bouton menu sans déclencher la redirection et en ouvrant le menu déroulant.
     * @param clickOffset : Changer sa valeur pour représenter la taille de l'icône indiquant que le bouton est un menu déroulant.
     */
    $( dropdownSelector + ' > a').on('click', function (e) {
      var $this = $(this);
      var clickOffset = 30;

      if (e.offsetX >= $this.width() - clickOffset) {
        e.preventDefault();
        e.stopPropagation();
        $this.parents( dropdownSelector ).addClass('.is-focused');
      }
    });

  }); // fin READY

})(jQuery);