(function($) {

    // functions 

    function prevent_default(){

        // menu desktop
        $('#site-header .nav-primary .nav li.prevent-default > a').on('click' , function(e){
            e.preventDefault();
        });

        // menu mobile
        $('#mobile-menu .nav-primary .nav li.prevent-default > a').on('click' , function(e){
            e.preventDefault();
            var li = $(this).parent('li');
            if(li.hasClass('menu-item-has-children')){
                if(li.hasClass('opened')){
                    li.removeClass('opened');
                    li.children('.sub-menu').stop().slideUp('fast');
                }else{
                    li.addClass('opened');
                    li.children('.sub-menu').stop().slideDown('fast');
                }
            }
        });
    }



    $(document).ready(function(){

        // document ready
        $('#mobile-menu .nav-primary .menu-item-has-children > .sub-menu-toggle').on('click' , function(e){
            e.preventDefault();
            var li = $(this).parent('.menu-item-has-children');
            var submenu = $(this).next('.sub-menu');
            if(li.hasClass('opened')){
                li.removeClass('opened');
                submenu.slideUp('fast');
                submenu.find('.menu-item-has-children').each(function(){
                    $(this).removeClass('opened');
                    $(this).children('.sub-menu').slideUp('fast');
                });
            }else{
                li.addClass('opened');
                submenu.slideDown('fast');
            }
        });


        prevent_default();

    });
    
    
    })(jQuery);